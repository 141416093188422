import { MdLanguage } from 'react-icons/md';
import { useIsJa } from '@/hooks/i18n';
import Link from 'next/link';
import { useRouter } from 'next/router';

type LanguageLinkProps = {
  children: React.ReactNode;
  disabled: boolean;
  locale: string;
};

function LanguageLink({ children, disabled, locale }: LanguageLinkProps) {
  const router = useRouter();

  return disabled ? (
    <span className="text-xs text-[#999999]">{children}</span>
  ) : (
    <Link href={{ pathname: router.pathname, query: router.query }} locale={locale} className="leading-[0]">
      <span className="text-xs text-role-action">{children}</span>
    </Link>
  );
}

// eslint-disable-next-line import/prefer-default-export
export function LanguageSwitchingButton() {
  const isJa = useIsJa();
  return (
    <div className="flex space-x-2">
      <MdLanguage color="#999999" size={16} />
      <LanguageLink disabled={isJa} locale="ja">
        日本語
      </LanguageLink>
      <LanguageLink disabled={!isJa} locale="en">
        English
      </LanguageLink>
    </div>
  );
}
