import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { signInWithGoogle, signInWithMicrosoft } from '@/lib/firebase-client';
import { storeBackUrl } from '@/lib/localStorage';
import LoginSelect from '@/components/LoginSelect';
import EmailLoginFormContainer from '@/containers/EmailLoginFormContainer';
import { SetNeedConfirmationErrorContext } from '@/contexts/account-linking';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FirebaseError } from '@firebase/util';
import { useUserinfo } from '@/hooks/mynumber/login';

function LoginSelectContainer() {
  const router = useRouter();
  // eslint-disable-next-line react/hook-use-state
  const [isEmailLoginSelected, setEmailLoginSelected] = useState(false);
  const setNeedConfirmationError = useContext(SetNeedConfirmationErrorContext);

  const { data } = useUserinfo();

  const googleLogin = () => {
    storeBackUrl(router);
    signInWithGoogle();
  };
  const msLogin = async () => {
    storeBackUrl(router);
    // すでに他のログインによってアカウントが存在していることが原因でMSログインに失敗する場合は
    // アカウントを紐づけるための画面に遷移するためにエラーをセットする
    try {
      await signInWithMicrosoft();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e instanceof FirebaseError && e.code === 'auth/account-exists-with-different-credential') {
        setNeedConfirmationError(e);
        return;
      }
      throw e;
    }
  };

  if (isEmailLoginSelected) {
    return <EmailLoginFormContainer callback={() => setEmailLoginSelected(false)} />;
  }
  return (
    <LoginSelect
      mynumberUsername={data && data.name}
      onClickGoogleLogin={googleLogin}
      onClickMsLogin={msLogin}
      onClickEmailLogin={() => setEmailLoginSelected(true)}
    />
  );
}

export default LoginSelectContainer;
