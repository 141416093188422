import Head from 'next/head';
import ScreenCenter from '@/components/ScreenCenter';
import AuthStateObserver from '@/containers/AuthStateObserver';
import LayoutWithLogo from '@/components/LayoutWithLogo';
import Footer from '@/containers/Footer';
import LoginSelectContainer from '@/containers/LoginSelectContainer';
import EmailVerifyRequiredContainer from '@/containers/EmailVerifyRequiredContainer';
import { useIsJa } from '@/hooks/i18n';
import { LanguageSwitchingButton } from '@/components/LanguageSwitchingButton';
import { Spinner } from '@toyokumo/kintoneapp-ui';
import { AccountLinkingContainer } from '@/containers/AccountLinkingContainer';

export default function Login() {
  const isJa = useIsJa();

  return (
    <>
      <Head>
        <title>ログイン - Toyokumo kintoneApp アカウント</title>
      </Head>

      <ScreenCenter>
        <LayoutWithLogo>
          <AuthStateObserver
            loading={
              <div className="flex justify-center">
                <Spinner />
              </div>
            }
            emailVerifying={<EmailVerifyRequiredContainer />}
            content={<LoginSelectContainer />}
            accountLinking={<AccountLinkingContainer />}
          />
        </LayoutWithLogo>
        {/* ひとまず日本語だけ */}
        {isJa && (
          <div className="mt-10 pb-10 sm:mt-4 sm:pb-0">
            <Footer />
          </div>
        )}
        <div className="fixed bottom-0 z-10 mt-4 w-full max-w-screen-sm bg-white pb-4 pl-4 pt-2 sm:static sm:pl-0">
          <LanguageSwitchingButton />
        </div>
      </ScreenCenter>
    </>
  );
}
