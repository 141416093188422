import { useRouter } from 'next/router';
import { useState } from 'react';
import { fetcher } from '@/lib/api-client';
import { NEXT_PUBLIC_BACKEND_URL } from '@/config/env-client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isTrustedRedirectHost } from '@/lib/url';

export const useRedirectState = () => {
  const router = useRouter();

  // error: デジタル認証アプリからredirect_uriで戻り、そこからの一連の処理のエラー内容をクエリパラメタから取り出す
  // backUrl: フォームブリッジやkViewerから飛ばされたとき、どのURLに戻るべきかをクエリパラメタから取り出す
  const { error, backUrl } = router.query;
  return {
    error: error as string | null,
    backUrl: backUrl as string | null,
  };
};

export const useLogin = () => {
  const { backUrl } = useRedirectState();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const m = useMutation<{ requestUri: string }, { message: string }>({
    mutationFn: () => {
      let url = `${NEXT_PUBLIC_BACKEND_URL}/api/mynumber/connect`;
      if (backUrl && isTrustedRedirectHost(backUrl)) {
        const query = new URLSearchParams();
        query.set('backUrl', backUrl);
        url += `?${query.toString()}`;
      }
      return fetcher.get(url, { credentials: 'include' });
    },
    onSuccess: (data) => {
      setIsRedirecting(true);
      location.href = data.requestUri;
    },
  });
  return {
    ...m,
    isRedirecting,
  };
};

type Userinfo = {
  name: string;
  address: string;
  birthdate: string | null;
  gender: '男性' | '女性' | null;
};

export const useUserinfo = () =>
  useQuery<Userinfo>({
    queryKey: ['mynumber', 'userinfo'],
    queryFn: () => fetcher.get(`${NEXT_PUBLIC_BACKEND_URL}/api/mynumber/userinfo`, { credentials: 'include' }),
  });
